import React from "react"
import { useInView } from "react-intersection-observer"
import Lottie from "react-lottie"

const InViewLottie = ({ animationData, loop = false, ...props }) => {
  const defaultOptions = {
    loop,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  const { ref, inView } = useInView({
    threshold: 0.7,
    triggerOnce: true
  });

  const lottieProps = loop ? { isPaused: !inView } : { isStopped: !inView }

  return (
    <div ref={ref} className='lottie-animation'>
      <Lottie
        options={defaultOptions}
        {...lottieProps}
        {...props}
      />
    </div>
  )
}

export default InViewLottie